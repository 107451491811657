<template>
    <v-container fluid>
        <v-row  v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col cols="12">
                <SwitchOrganisation />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SwitchOrganisation from '@/components/SwitchOrganisation'
export default {
    name:'SwitchOrganisationView',
    data(){
        return {}
    },
    components:{
        SwitchOrganisation
    },
    mounted() {
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>
.container{
    margin-top: -1%;
}
</style>