<template>
    <v-container fluid :dark="$store.getters.getColorPalette().isDark">

        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
          <v-col cols="12">
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" :dark="$store.getters.getColorPalette().isDark" >
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$store.getters.getTextMap().search"
                single-line
                hide-details
              :dark="$store.getters.getColorPalette().isDark"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="organisations"
              item-key="org_id"
              :search="search"
              
           :dark="$store.getters.getColorPalette().isDark"
              :loading="loading"
            loading-text="Loading... Please wait"
            >
            <template v-slot:top>
              <v-toolbar
                flat
            
              >
                <v-toolbar-title>{{ $store.getters.getTextMap().switch_to }}</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                small outlined class="switch" :color="$store.getters.getColorPalette().accentCode"
                    @click="returnHome"
                  >
                  {{ $store.getters.getTextMap().switch_to_base_org }}
                  </v-btn>
              </v-toolbar>
                  </template>
                  
                  <template v-slot:item.action="{item}">
                    <v-btn small outlined class="switch" :color="$store.getters.getColorPalette().accentCode" @click="switchOrg(item)">{{ $store.getters.getTextMap().switch }}</v-btn>
                  </template>
            </v-data-table>
          </v-card>
          </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'SwitchOrganisation',
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                organisation_id:null
            },
                loading:false,
                info:'',
                showDismissibleAlert:false,
                valid:false,
                search:null,
                headers:[
                  {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'organisation_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
            
          },
          { text: 'Name', value: 'name', class: " text-capitalize  "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Action', value: 'action', class: " text-capitalize  "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
                ],
        nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 40 && v.length > 0) || 'Name must be less than 40 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
        }
    },
    computed:{
        organisations(){
            let orgs=[]
            for(let i of this.$store.state.maintainedOrgs){
              //console.log(this.$store.state.organisation)
              if(this.$store.state.organisation && this.$store.state.organisation.organisation_id == i.organisation_id){
                //console.log(i.organisation_id)
                continue
              }
            orgs.push({name:i.name?i.name:i.organisation_id,organisation_id:i.organisation_id})
            }
            return orgs
        }
    },
    methods:{
      switchOrg(item){
        //console.log(item)
        axios
        .post(this.$store.state.api + "switchOrganisation", item,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setJWT", response.data.jwt);
            this.$store.commit("setLoggedIn", true)
            localStorage.setItem('enture_token',response.data.jwt)
            this.$store.commit("toggleSettingMode")
            //console.log(response.data.jwt);
            this.$store.dispatch("refresh");
            this.$store.commit("setNotifications", [])
            
            //setTimeout(()=>{this.$store.dispatch("refresh")}, 30000);
            //console.log("redirecting to dashboard")
            
            //this.onReset()
            this.$router.push("/dashboard");
            this.$emit('close')
          } else {
            this.info = response.data.error;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
      },
        /*onSubmit(){
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
      axios
        .post(this.$store.state.api + "switchOrganisation", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setJWT", response.data.jwt);
            this.$store.commit("setLoggedIn", true)
            localStorage.setItem('enture_token',response.data.jwt)
            this.$store.commit("toggleSettingMode")
            //console.log(response.data.jwt);
            this.$store.dispatch("refresh");
            
            //setTimeout(()=>{this.$store.dispatch("refresh")}, 30000);
            //console.log("redirecting to dashboard")
            
            this.onReset()
            this.$router.push("/dashboard");
            this.$emit('close')
          } else {
            this.info = response.data.error;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          //this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },*/
        returnHome(){
            
            this.loading = true;
      axios
        .get(this.$store.state.api + "returnToHomeOrganisation",{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setJWT", response.data.jwt);
            localStorage.setItem('enture_token',response.data.jwt)
            this.$store.commit("setLoggedIn", true)
            //console.log(response.data.jwt);
            this.$store.dispatch("refresh");
            
            //setTimeout(()=>{this.$store.dispatch("refresh")}, 30000);
            //console.log("redirecting to dashboard")
            
            //this.onReset()
            this.$router.push("/dashboard");
            this.$emit('close')
          } else {
            this.info = response.data.error;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            
        },
        /*onReset(){
            this.$refs.orgForm.reset()
            
            this.$emit('close')
            
        }*/
    }
}
</script>
<style scoped>
.switch{
  border-color:teal;
}
</style>